<template>
  <div id="annual-report">
    <ul>
      <li
        v-for="(item, index) in data"
        :key="index"
        @click="toUrl(item.urlPath)"
      >
        <div class="cover">
          <img :src="'/files/' + item.img + '/raw'" alt="" />
        </div>
        <div class="report-name">
          <div>{{ item.name }}</div>
        </div>
      </li>
    </ul>
    <!-- 分页 -->
    <!-- <Pagination
      :pageCount="newList.length"
      :currentPage="activeIndex"
      @prePage="handlePrePage"
      @nextPage="handleNextPage"
      @switchPage="handleSwitchPage($event)"
    /> -->
  </div>
</template>

<script>
// import Pagination from "@/components/Pagination";
import { reportPage } from "@/api/frontReport";

export default {
  name: "AuditReport",
  components: {
    // Pagination
  },
  data() {
    return {
      currentIndex: 0,
      page: 1,
      pageSize: 100,
      search: {
        type: 18
      },
      data: []
    };
  },
  methods: {
    handlePrePage() {
      if (this.currentIndex == 0) {
        return;
      }
      this.currentIndex -= 1;
    },
    handleNextPage() {
      if (this.currentIndex == this.newList.length - 1) {
        return;
      }
      this.currentIndex += 1;
    },
    handleSwitchPage(index) {
      this.currentIndex = index;
    },
    toUrl(url) {
      window.open(url);
    },
    async queryPage(page = this.page, pageSize = this.pageSize) {
      this.page = page;
      this.pageSize = pageSize;

      try {
        const res = await reportPage({
          page,
          pageSize,
          param: this.search
        });
        console.log(res);
        this.page = res.page;
        this.pageSize = res.pageSize;
        this.data = res.data;
      } catch (e) {
        this.page = 1;
        this.pageSize = 100;
        this.data = [];
      }
    }
  },
  mounted() {
    this.queryPage();
  }
};
</script>

<style lang="scss" scoped>
ul {
  width: 1240px;
  padding: 0 40px;
  // min-height: 1340px;
  margin: 0 auto;
  // display: flex;
  // justify-content: space-between;
  // flex-wrap: wrap;
}
ul li {
  width: 250px;
  height: 410px;
  margin: 0 20px;
  margin-bottom: 36px;
  float: left;
  cursor: pointer;
  .cover {
    width: 100%;
    height: 320px;
    border: 1px solid #ccc;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .report-name {
    width: 100%;
    height: 90px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      text-align: center;
    }
  }
}
</style>
